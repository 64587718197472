import React from 'react';
import {
  BooleanField,
  ChipField,
  ReferenceArrayField,
  ReferenceField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import MarkdownField from '@src/components/MarkdownField';
import ProFileField from '@src/components/ProFileField';
import Title from '@src/components/Title';
import VideoField from '@src/components/VideoField';

const Show: React.FC = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" source="title" />}>
    <TabbedShowLayout>
      <Tab label="resources.learn/course/chapter.tabs.details">
        <TextField source="id" />
        <TextField source="slug" />
        <ReferenceField source="course" reference="learn/courses" link="show">
          <TextField source="title" />
        </ReferenceField>
        <TextField source="title" />
        <ReferenceField source="teacher" reference="teacher" link="show">
          <TextField source="email_contact" />
        </ReferenceField>
        <TextField source="chapter_number" />
        <ReferenceArrayField reference="tags" source="tags">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="time" />
        <BooleanField source="active" />
        <BooleanField source="is_demo" />
      </Tab>
      <Tab
        label="resources.learn/course/chapter.tabs.description"
        path="description"
      >
        <MarkdownField source="description" addLabel={false} />
      </Tab>
      <Tab label="resources.learn/course/chapter.tabs.media" path="media">
        <VideoField
          source="presigned.source"
          label="resources.learn/course/chapter.fields.video_source"
        />
        <VideoField
          source="presigned.q_720"
          label="resources.learn/course/chapter.fields.video_720"
        />
        <VideoField
          source="presigned.q_480"
          label="resources.learn/course/chapter.fields.video_480"
        />
        <ProFileField
          source="demo.source"
          label="resources.learn/course/chapter.fields.demo"
        />
        <ProFileField
          source="presigned.files"
          label="resources.learn/course/chapter.fields.files"
        />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
