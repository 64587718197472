import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark as style } from 'react-syntax-highlighter/dist/cjs/styles/prism';

type Props = {
  value: string;
  language?: string;
};

const codeTagProps = { dir: 'ltr' };
const lineNumberStyle = {
  minWidth: '2em',
  paddingRight: '10px',
};
const lineProps: lineTagPropsFunction | React.HTMLProps<HTMLElement> = {
  style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' },
};

const CodeBlock: React.FC<Props> = ({ value, language }) => {
  return (
    <SyntaxHighlighter
      language={language}
      style={style}
      codeTagProps={codeTagProps}
      lineProps={lineProps}
      lineNumberStyle={lineNumberStyle}
      wrapLines
      showLineNumbers
    >
      {value}
    </SyntaxHighlighter>
  );
};

export default React.memo(CodeBlock);
