import React from 'react';
import { Datagrid, List as RaList, TextField } from 'react-admin';

import FullNameField from '@src/components/FullNameField';
import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import ResourceHelmet from '@src/components/ResourceHelmet';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <>
    <ResourceHelmet {...props} />
    <RaList
      {...props}
      pagination={<Pagination />}
      perPage={perPage}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <FullNameField source="full_name" />
        <TextField source="username" />
        <TextField source="email" />
        <LocaleDateField source="date_joined" />
      </Datagrid>
    </RaList>
  </>
);

export default List;
