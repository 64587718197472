import React from 'react';
import { DashboardMenuItem, MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@material-ui/core';
// import ForumIcon from '@material-ui/icons/Forum';
import ClubIcon from '@material-ui/icons/GroupWork';
import LearnIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';

import * as Resources from '@src/resources';
import { AppState } from '@src/store/types';

import MenuLinkContext from './MenuLinkContext';
import ResourceMenuLink from './ResourceMenuLink';
import SubMenu from './SubMenu';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: React.FC<Props> = ({ onMenuClick, dense, logout }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme.name); // force rerender on theme change

  const value = React.useMemo(() => ({ onMenuClick, dense, open }), [
    dense,
    onMenuClick,
    open,
  ]);

  return (
    <MenuLinkContext.Provider value={value}>
      <nav>
        <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
        <ResourceMenuLink Resource={Resources.User} />
        <ResourceMenuLink Resource={Resources.Skill} />
        <ResourceMenuLink Resource={Resources.Tag} />

        <SubMenu icon={<ClubIcon />} name="menus.club">
          <ResourceMenuLink Resource={Resources.Post} />
        </SubMenu>
        <SubMenu icon={<LearnIcon />} name="menus.learn">
          <ResourceMenuLink Resource={Resources.Teacher} />
          <ResourceMenuLink Resource={Resources.Course} />
        </SubMenu>
        {/*<SubMenu icon={<ForumIcon />} name="menus.forum">*/}
        {/*  <ResourceMenuLink Resource={Resources.Question} />*/}
        {/*</SubMenu>*/}

        {isXSmall && (
          <MenuItemLink
            to="/configuration"
            primaryText={translate('ra.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        )}
        {isXSmall && logout}
      </nav>
    </MenuLinkContext.Provider>
  );
};

export default Menu;
