import React from 'react';
import {
  ChipField,
  Datagrid,
  EditButton,
  List as RaList,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';

// import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import Title from '@src/components/Title';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <RaList
    {...props}
    pagination={<Pagination />}
    perPage={perPage}
    sort={{ field: 'created_at', order: 'DESC' }}
    title={<Title {...props} type="list" source="title" />}
  >
    <Datagrid rowClick="show">
      <TextField source="title" />
      <ReferenceArrayField reference="teacher" source="teachers">
        <SingleFieldList linkType="show">
          <ChipField source="email_contact" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
    </Datagrid>
  </RaList>
);

export default List;
