import { keyBy } from 'lodash';

export const status = [
  { id: 1, name: 'resources.club/posts.choices.status.pending' },
  { id: 2, name: 'resources.club/posts.choices.status.in-progress' },
  { id: 3, name: 'resources.club/posts.choices.status.approved' },
  { id: 4, name: 'resources.club/posts.choices.status.rejected' },
];

export const statusMap = keyBy(status, 'id');
