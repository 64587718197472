import React from 'react';
import { Create as RaCreate } from 'react-admin';

import Title from '@src/components/Title';

import Form from './Form';

const Create: React.FC = (props) => (
  <RaCreate {...props} title={<Title {...props} type="create" />}>
    <Form />
  </RaCreate>
);

export default Create;
