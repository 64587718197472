import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CodeBlock from './CodeBlock';
import HeadingBlock from './HeadingBlock';
import ImageBlock from './ImageBlock';
import ParagraphBlock from './ParagraphBlock';

type Props = {
  children: string;
};

const useStyles = makeStyles((theme) => ({
  content: {
    fontFamily: theme.typography.fontFamily,
    margin: theme.spacing(1, 0),
  },
}));

const renderers = {
  code: CodeBlock,
  image: ImageBlock,
  link: Link,
  paragraph: ParagraphBlock,
  heading: HeadingBlock,
};

const Markdown: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <ReactMarkdown
      className={classes.content}
      escapeHtml
      source={children}
      renderers={renderers}
    />
  );
};

export default Markdown;
