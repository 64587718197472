const Theme = (language = 'fa', rtl = true) => {
  const direction = rtl ? 'rtl' : 'ltr';
  const fontFamily =
    language === 'fa' ? 'IRANSansFaNum, sans-serif' : 'IRANSans, sans-serif';
  document.getElementsByTagName('html')[0].setAttribute('dir', direction);
  return {
    direction,
    typography: {
      fontFamily,
    },
  };
};

export default Theme;
