import React from 'react';
import { Layout } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';

import useTheme from '@src/hooks/useTheme';

import AppBar from './AppBar';
import Menu from './Menu';

const CustomLayout: React.FC = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <>
      <Layout
        {...props}
        appBar={AppBar}
        // @ts-ignore
        menu={Menu}
        // @ts-ignore
        theme={theme}
      >
        <CssBaseline />
        {children}
      </Layout>
    </>
  );
};

export default CustomLayout;
