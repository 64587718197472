import React from 'react';

const Logo: React.FC = () => {
  return (
    <img
      width={44}
      height={44}
      src={require('@src/assets/images/logo.png')}
      alt="Logo"
    />
  );
};

export default React.memo(Logo);
