import Icon from '@material-ui/icons/VideoLibrary';

import Create from './Create';
import Edit from './Edit';
import Show from './Show';

export default {
  name: 'learn/course/chapter',
  icon: Icon,
  show: Show,
  create: Create,
  edit: Edit,
};
