import { merge } from 'lodash';
import messages from 'ra-language-english';

export default merge(messages, {
  ra: {
    title: 'CodeByMe',
    auth: {
      sign_up: 'Register',
      email: 'Email',
      name: 'Name',
      profile: 'Profile',
      user_menu: 'Account',
    },
    download: 'Download',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      welcome: {
        title: 'Welcome to CodeByMe Dashboard',
        description: 'Here you can manage everything!!!',
        web_button: 'Website',
      },
    },
    copied: 'Copied!',
    meta: 'Meta',
    comments: 'Comments',
    search: 'Search',
    publish: 'Publish',
  },
});
