import React from 'react';
import {
  Title as RaTitle,
  useGetResourceLabel,
  useTranslate,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import { get } from 'lodash';

type Props = {
  source?: string;
  type: 'list' | 'show' | 'create' | 'edit';
  resource?: any;
  id?: string;
  record?: any;
};

const Title: React.FC<Props> = ({
  type,
  resource,
  id,
  record,
  source = 'id',
}) => {
  const translate = useTranslate();
  const getResourceLabel = useGetResourceLabel();

  const title = React.useMemo(() => {
    switch (type) {
      case 'create':
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 1),
        });
      case 'edit':
      case 'show': {
        const newId = get(record, source, id);
        if (newId && newId !== id) {
          return translate(`ra.page.${type}`, {
            name: getResourceLabel(resource, 1),
            id: newId,
            record,
          }).replace('#', '');
        }
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 1),
          id,
          record,
        });
      }
      case 'list':
        return translate(`ra.page.${type}`, {
          name: getResourceLabel(resource, 2),
        });
    }
  }, [getResourceLabel, id, record, resource, source, translate, type]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <RaTitle title={title} />
    </>
  );
};

export default Title;
