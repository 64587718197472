import React from 'react';
import {
  BooleanField,
  Datagrid,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import FullNameField from '@src/components/FullNameField';
import LocaleDateField from '@src/components/LocaleDateField';
import MarkdownField from '@src/components/MarkdownField';
import Title from '@src/components/Title';

const Show: React.FC<any> = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" />}>
    <TabbedShowLayout>
      <Tab label="resources.forum/answers.tabs.details">
        <TextField source="id" />
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
        <BooleanField source="is_updated" />
        <BooleanField source="is_correct" />
        <NumberField source="score" />
        <NumberField source="status" />
        <ReferenceField source="owner" reference="users">
          <FullNameField />
        </ReferenceField>
        <MarkdownField source="body" />
      </Tab>
      <Tab label="resources.forum/questions.tabs.comments" path="comments">
        <ReferenceManyField
          target="answer_id"
          reference="forum/answer/replies"
          addLabel={false}
        >
          <Datagrid>
            <MarkdownField source="body" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
