import React from 'react';
import { FileField, FileInput, useInput, useNotify } from 'react-admin';
import Progress from '@material-ui/core/LinearProgress';
import { get } from 'lodash';

import uploadClient from '@src/data/uploadClient';
import { Attribute, Section } from '@src/types';

type Props = {
  private: boolean;
  section: Section;
  attribute: Attribute;
  source: string;
  accept: string;
  multiple?: boolean;
  label?: string;
  validate?: any;
  record?: any;
};

const UploadFileInput: React.FC<Props> = ({ accept, ...props }) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const notify = useNotify();
  const [percent, setPercent] = React.useState(0);
  const [preview, setPreview] = React.useState<string | undefined>(
    props.private
      ? get(props.record, `presigned.${props.source}`)
      : get(props.record, props.source)
  );
  const [uploading, setUploading] = React.useState(false);
  const handleUploadProgress = React.useCallback((progressEvent) => {
    const newPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (newPercent >= 100) {
      setPercent(100);
    } else {
      setPercent(newPercent);
    }
  }, []);
  const handleChange = React.useCallback(
    async (file: File | null) => {
      if (file instanceof File) {
        try {
          setUploading(true);
          const data = new FormData();
          const url = {
            source: '',
          };
          data.set('chunk', file);
          data.set('private', props.private ? 'true' : 'false');
          data.set('section', props.section.toString());
          data.set('attribute', props.attribute.toString());
          const response = await uploadClient.post(
            '/cms/learn/course/upload',
            data,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: handleUploadProgress,
            }
          );
          url.source = response.data.url;
          if (props.private) {
            setPreview(response.data.presigned);
          } else {
            setPreview(response.data.url);
          }
          onChange(url);
          setUploading(false);
          setPercent(-1);
        } catch (e) {
          onChange(null);
          setPreview(undefined);
          setUploading(false);
          setPercent(-1);
          notify(`File upload failed: ${e.message}`, 'error');
        }
      } else {
        onChange(null);
        setPreview(undefined);
      }
    },
    [
      props.private,
      props.section,
      props.attribute,
      handleUploadProgress,
      onChange,
      notify,
    ]
  );

  const handleFormat = React.useCallback((value) => ({ src: value }), []);
  const handleParse = React.useCallback(
    (value) => ({
      source: value?.src?.source,
    }),
    []
  );
  return (
    <div>
      <FileInput
        {...props}
        onChange={handleChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        accept={accept}
        multiple={false}
        format={handleFormat}
        parse={handleParse}
        label={props.label}
      >
        <>
          <FileField
            record={{ id: props?.record?.id, preview }}
            source="preview"
            title={name}
            download
            target="_blank"
          />
        </>
      </FileInput>
      {uploading && (
        <Progress
          variant={percent !== -1 ? 'determinate' : undefined}
          value={percent}
        />
      )}
    </div>
  );
};

UploadFileInput.defaultProps = {
  multiple: false,
};

export default UploadFileInput;
