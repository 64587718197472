import React from 'react';
import { useTranslate } from 'react-admin';
import { Helmet } from 'react-helmet';

const ResourceHelmet: React.FC<any> = (props) => {
  const translate = useTranslate();

  return (
    <Helmet>
      <title>
        {translate(`resources.${props.resource}.name`, { smart_count: 2 })}
      </title>
    </Helmet>
  );
};

export default ResourceHelmet;
