import merge from 'lodash/merge';

import baseTheme from './base';

const Theme = (language = 'fa', rtl = true) => {
  return merge(baseTheme(language, rtl), {
    overrides: {
      RaFileField: {
        root: {
          '& a': {
            textDecoration: 'none',
            color: '#5DD39E',
          },
        },
      },
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: '#222130',
          },
        },
      },
    },
    palette: {
      type: 'dark',
      primary: {
        main: '#5DD39E',
        contrastText: '#222130',
      },
      secondary: {
        main: '#525174',
        contrastText: '#222130',
      },
      background: {
        default: '#222130',
        paper: '#333249',
      },
    },
  });
};

export default Theme;
