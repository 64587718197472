import { perPage as defaultPerPage } from '@src/configuration';
import { LegacyDataProvider } from 'ra-core/src/types';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';

import client from './client';
import generateBaseURL from './generateBaseURL';

const dataProvider: LegacyDataProvider = async (type, resource, payload) => {
  try {
    const baseURL = generateBaseURL(resource, type);
    switch (type) {
      case GET_LIST: {
        const {
          pagination: { page, perPage } = {
            page: 1,
            perPage: defaultPerPage,
          },
          sort: { field, order } = { field: 'id', order: 'desc' },
          filter,
        } = payload;
        const response = await client.get(`${baseURL}`, {
          params: {
            page,
            page_size: perPage,
            ordering: `${order.toLowerCase() === 'desc' ? '-' : ''}${field}`,
            ...filter,
          },
        });
        return { data: response.data.results, total: response.data.count };
      }
      case GET_MANY_REFERENCE: {
        const {
          pagination: { page, perPage } = {
            page: 1,
            perPage: defaultPerPage,
          },
          sort: { field, order } = { field: 'id', order: 'desc' },
          filter,
          target,
          id,
        } = payload;
        const response = await client.get(`${baseURL}`, {
          params: {
            page,
            page_size: perPage,
            ordering: `${order.toLowerCase() === 'desc' ? '-' : ''}${field}`,
            [target]: id,
            ...filter,
          },
        });
        return { data: response.data.results, total: response.data.count };
      }
      case GET_MANY: {
        const { ids } = payload;
        const response = await client.get(`${baseURL}`, {
          params: { ids },
        });
        return { data: response.data.results, total: response.data.count };
      }
      case GET_ONE: {
        const { id } = payload;
        const response = await client.get(`${baseURL}/${id}`);
        return { data: response.data };
      }
      case UPDATE: {
        const { id, data } = payload;
        const response = await client.put(`${baseURL}/${id}`, data);
        return { data: response.data };
      }
      case CREATE: {
        const { data } = payload;
        const response = await client.post(`${baseURL}`, data);
        return { data: response.data };
      }
      case DELETE: {
        const { id } = payload;
        await client.delete(`${baseURL}/${id}`);
        return { data: id };
      }
      case DELETE_MANY: {
        const { ids = [] } = payload;
        // TODO show exactly which ids deleted
        await Promise.all(
          // @ts-ignore
          ids.map((id) => dataProvider(DELETE, resource, { id }))
        );
        return { data: ids };
      }
      default:
        return new Error(`Unsupported Data Provider request type ${type}`);
    }
  } catch (e) {
    return await Promise.reject(e);
  }
};

export default dataProvider;
