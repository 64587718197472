import React from 'react';

const FullNameField: React.FC<any> = ({ record }) => {
  return (
    <span>
      {record.first_name} {record.last_name}
    </span>
  );
};

FullNameField.defaultProps = {
  addLabel: true,
};

export default React.memo(FullNameField);
