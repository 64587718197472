import React from 'react';
import ReactPlayer from 'react-player';
import { get } from 'lodash';

type Props = {
  record?: object;
  source: string;
  label?: any;
  addLabel?: boolean;
  fullWidth?: boolean;
};

const VideoField: React.FC<Props> = ({ source, record = {} }) => {
  const url = get(record, source);
  if (!url) {
    return null;
  }
  return <ReactPlayer controls url={url} />;
};

VideoField.defaultProps = {
  addLabel: true,
};

export default VideoField;
