import React from 'react';
import { EditButton, TopToolbar } from 'react-admin';

import PublishPost from './PublishPost';

type Props = {
  basePath?: string;
  data?: any;
};

const ShowActions: React.FC<Props> = ({ basePath, data, ...rest }) => {
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <PublishPost record={data} {...rest} />
    </TopToolbar>
  );
};

export default ShowActions;
