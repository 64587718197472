import React from 'react';
import {
  ImageField,
  ReferenceField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="resources.course.tabs.details">
        <TextField source="id" />
        <TextField source="slug" />
        <TextField source="name" />
        <TextField source="name_fa" />
        <ReferenceField source="category" reference="skill/category">
          <TextField source="name" />
        </ReferenceField>
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
        <UrlField source="document_link" />
        <UrlField source="back_link" />
        <TextField source="description" />
        <ImageField source="logo.source" label="resources.skill.fields.logo" />
        <ImageField
          source="header.source"
          label="resources.skill.fields.header"
        />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
