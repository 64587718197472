import { merge } from 'lodash';
import messages from 'ra-language-farsi';

export default merge(messages, {
  menus: {
    club: 'کلاب',
    learn: 'آموزش',
    forum: 'پرسش‌وپاسخ',
  },
  ra: {
    title: 'کد‌بای‌می',
    auth: {
      email: 'پست الکترونیک',
      name: 'نام',
      profile: 'پروفایل',
      user_menu: 'حساب کاربری',
    },
    input: {
      markdown: {
        write: 'نوشتن',
        preview: 'پیش‌نمایش',
        uploadingImage: 'در حال آپلود عکس',
        pasteDropSelect:
          'با کشیدن و رها کردن ، انتخاب و جایگذاری فایل ها ، آنها را پیوست کنید.',
      },
    },
    download: 'دانلود',
    configuration: 'تنظیمات',
    language: 'زبان',
    theme: {
      name: 'تم',
      light: 'روشن',
      dark: 'تاریک',
    },
    dashboard: {
      welcome: {
        title: 'به داشبورد کدبای‌می خوش‌آمدید',
        description: 'همه چی رو اینجا مدیریت کنید!!!',
        web_button: 'وب سایت',
      },
    },
    copied: 'Copied!',
    meta: 'Meta',
    search: 'Search',
  },
  resources: {
    users: {
      name: 'کاربر |||| کاربران',
      fields: {
        id: 'شناسه',
        username: 'نام کاربری',
        full_name: 'نام و نام خانوادگی',
        first_name: 'نام',
        last_name: 'نام خانوادگی',
        gender: 'جنسیت',
        bio: 'بیوگرافی',
        city: 'شهر',
        mobile_number: 'شماره موبایل',
        is_developer: 'توسعه دهنده',
        is_verified: 'وریفای ',
        avatar: 'آواتار',
        header: 'هدر',
        created_at: 'تاریخ ایجاد',
        date_joined: 'تاریخ ثبت‌نام',
        updated_at: 'تاریخ به روز رسانی',
        birth_date: 'تاریخ تولد',
        email: 'ایمیل',
        github: 'گیت‌هاب',
        linkedin: 'لینکداین',
        website_url: 'آدرس وب سایت',
        token: 'توکن',
        wallet: {
          name: 'کیف پول',
          user_charge: 'اعتبار کاربر',
        },
      },
      tabs: {
        wallet: 'کیف پول',
        details: 'جزئیات',
      },
      choices: {
        gender: {
          male: 'مرد',
          female: 'زن',
          unknown: 'مشخص نشده',
        },
      },
    },
    'club/posts': {
      name: 'پست |||| پست‌ها',
      fields: {
        id: 'شناسه',
        title: 'عنوان',
        slug: 'اسلاگ',
        owner: 'نویسنده',
        created_at: 'تاریخ ایجاد',
        updated_at: 'تاریخ به روز رسانی',
        current_status: 'وضعیت',
        description: 'محتوا',
        is_pin: 'پین',
        is_verify: 'وریفای ',
        logo: 'لوگو',
        header: 'هدر',
      },
      choices: {
        status: {
          approved: 'تایید شده',
          rejected: 'رد شده',
          'in-progress': 'در حال بررسی',
          pending: 'در انتظار بررسی',
        },
      },
      actions: {
        status_set_succeeded: 'وضعیت به %{status} تغییر کرد',
        status_set_failed: 'تعیین وضعیت با خطا مواجه شذ',
      },
      tabs: {
        content: 'محتوا',
        details: 'جزئیات',
        media: 'رسانه',
      },
    },
    replyQuestion: {
      name: 'ریپلای به سوال |||| ریپلای‌ها به سوال',
      grid: {
        question: 'سوال',
      },
    },
    replyAnswer: {
      name: 'ریپلای به جواب |||| ریپلای‌ها به جواب',
      grid: {
        question: 'سوال',
      },
    },
    'learn/courses': {
      name: 'آموزش |||| آموزش‌ها',
      fields: {
        title: 'عنوان',
        description: 'توضیحات',
        teachers: 'مدرسین',
        logo: 'لوگو',
        header: 'هدر',
        introduction: 'مقدمه',
        category: 'دسته بندی',
        priority: 'اولویت',
        tags: 'تگ‌ها',
        back_link: 'بک لینک',
        document_link: 'لینک داکیومنت',
        time: 'زمان',
        price: {
          final_price: 'قیمت نهایی',
          tax: 'مالیات',
          show: 'قیمت نمایشی',
        },
        is_pin: 'پین',
        id: 'شناسه',
        token: 'توکن',
        version: 'نسخه',
        slug: 'اسلاگ',
        active: 'فعال',
      },
      actions: {
        new_chapter: 'بخش جدید',
      },
      tabs: {
        details: 'جزئیات',
        description: 'توضیحات',
        sections: 'بخش‌ها',
        price: 'قیمت',
      },
    },
    'learn/course/chapter': {
      name: 'بخش |||| بخش‌ها',
      fields: {
        id: 'شناسه',
        description: 'توضیحات',
        course: 'عنوان آموزش',
        title: 'عنوان',
        teacher: 'مدرس',
        chapter_number: 'شماره',
        slug: 'اسلاگ',
        tags: 'تگ‌ها',
        token: 'توکن',
        video_source: 'ویدیو (منبع)',
        video_720: 'ویدیو (720p)',
        video_480: 'ویدیو (480p)',
        audio: 'صدا',
        demo: 'دمو',
        files: 'فایل‌ها',
        poster: 'پوستر',
        header: 'هدر',
        active: 'فعال',
        is_demo: 'دمو',
        time: 'زمان',
      },
      tabs: {
        details: 'جزئیات',
        description: 'توضیحات',
        media: 'رسانه',
      },
    },
    skill: {
      name: 'مهارت |||| مهارت‌ها',
      fields: {
        id: 'شناسه',
        name: 'نام',
        name_fa: 'نام به فارسی',
        description: 'توضیحات',
        created_at: 'تاریخ ایجاد',
        updated_at: 'تاریخ به روز رسانی',
        slug: 'اسلاگ',
        category: 'دسته بندی',
        document_link: 'لینک داکیومنت',
        back_link: 'بک لینک',
        logo: 'لوگو',
        header: 'هدر',
      },
      tabs: {
        details: 'جزئیات',
      },
    },
    tags: {
      name: 'برچسب |||| برچسب‌ها',
      fields: {
        id: 'شناسه',
        name: 'نام',
        name_fa: 'نام به فارسی',
        description: 'توضیحات',
        created_at: 'تاریخ ایجاد',
        updated_at: 'تاریخ به روز رسانی',
        slug: 'اسلاگ',
      },
      tabs: {
        details: 'جزئیات',
        description: 'توضیحات',
      },
    },
    teacher: {
      name: 'مدرس |||| مدرسین',
      fields: {
        id: 'شناسه',
        email_contact: 'پست الکترونیک',
        phone_contact: 'شماره تماس',
        active: 'فعال',
        description: 'توضیحات',
        instagram: 'اینستاگرام',
        user: 'کاربر',
        token: 'توکن',
        created_at: 'تاریخ ایجاد',
        updated_at: 'تاریخ به روز رسانی',
      },
      tabs: {
        details: 'جزئیات',
      },
    },
    'forum/questions': {
      name: 'سوال |||| سوالات',
      fields: {
        title: 'عنوان',
        description: 'متن سوال',
        created_at: 'تاریخ ایجاد',
        username: 'نام کاربری',
        first_name: 'نام',
        last_name: 'نام خانوادگی',
        full_name: 'نام و نام خانوادگی',
        status: {
          name: 'وضعیت',
          waiting: 'در انتظار',
          confirmed: 'تایید شده',
          rejected: 'رد شده',
          accept: 'با موفقیت تایید شد',
          reject: 'با موفقیت رد شد',
        },
        button: {
          accept: 'قبول',
          reject: 'رد',
        },
        is_updated: 'وضعیت به روز رسانی',
        archive: 'وضعیت آرشیو',
        id: 'شناسه',
        token: 'توکن',
        tags: 'تگ‌ها',
        slug: 'اسلاگ',
        monitor: 'مانیتور',
        current_status: 'وضعیت',
      },
      tabs: {
        details: 'جزئیات',
        answers: 'پاسخ‌ها',
        comments: 'کامنت‌ها',
      },
    },
    'forum/answers': {
      name: 'پاسخ |||| پاسخ‌ها',
      fields: {
        id: 'شناسه',
        body: 'محتوا',
        created_at: 'تاریخ ایجاد',
        updated_at: 'تاریخ به روز رسانی',
        score: 'امتیاز',
        is_correct: 'پاسخ درست',
        status: 'وضعیت',
        is_updated: 'وضعیت به روز رسانی',
        current_status: 'وضعیت',
        owner: 'نویسنده',
      },
      tabs: {
        details: 'جزئیات',
        comments: 'کامنت‌ها',
      },
    },
  },
});
