import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  EditButton,
  ImageField,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin';

import MarkdownField from '@src/components/MarkdownField';
import Pagination from '@src/components/Pagination';
import ProFileField from '@src/components/ProFileField';
import Title from '@src/components/Title';
import { perPage } from '@src/configuration';

import ShowActions from './ShowActions';

const Show: React.FC = (props) => {
  return (
    <RaShow
      {...props}
      actions={<ShowActions />}
      title={<Title {...props} type="show" source="title" />}
    >
      <TabbedShowLayout>
        <Tab label="resources.learn/courses.tabs.details">
          <TextField source="id" />
          <TextField source="slug" />
          <TextField source="title" />
          <TextField source="version" />
          <ReferenceArrayField reference="teacher" source="teachers">
            <SingleFieldList linkType="show">
              <ChipField source="email_contact" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ImageField
            source="header.source"
            label="resources.learn/courses.fields.header"
          />
          <ImageField
            source="logo.source"
            label="resources.learn/courses.fields.logo"
          />
          <TextField source="priority" />
          <TextField source="time" />
          <BooleanField source="is_pin" />
          <BooleanField source="active" />
          <UrlField source="back_link" />
          <UrlField source="document_link" />
          <ProFileField
            label="resources.learn/courses.fields.introduction"
            source="introduction.source"
          />
          <ReferenceArrayField reference="tags" source="tags">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceField source="category" reference="skill" link="show">
            <TextField source="name" />
          </ReferenceField>
        </Tab>
        <Tab
          label="resources.learn/courses.tabs.description"
          path="description"
        >
          <MarkdownField source="description" addLabel={false} />
        </Tab>
        <Tab label="resources.learn/courses.tabs.price" path="price">
          <TextField source="price.final_price" />
          <TextField source="price.show" />
          <TextField source="price.tax" />
        </Tab>
        <Tab label="resources.learn/courses.tabs.sections" path="sections">
          <ReferenceManyField
            addLabel={false}
            target="course"
            reference="learn/course/chapter"
            pagination={<Pagination />}
            perPage={perPage}
            sort={{ field: 'chapter_number', order: 'ASC' }}
          >
            <Datagrid rowClick="show">
              <TextField source="chapter_number" />
              <TextField source="title" sortable={false} />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </RaShow>
  );
};

export default Show;
