import React from 'react';
import { Edit as RaEdit } from 'react-admin';

import Title from '@src/components/Title';

import Form from './Form';

const Edit: React.FC = (props) => (
  <RaEdit {...props} title={<Title {...props} type="edit" source="title" />}>
    <Form />
  </RaEdit>
);

export default Edit;
