import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  FormTab,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';

import MarkdownInput from '@src/components/MarkdownInput';
import UploadImageInput from '@src/components/UploadImageInput';
import { attributeChoices, sectionChoices } from '@src/configuration';

const Form: React.FC<any> = (props) => (
  <TabbedForm {...props} redirect="show">
    <FormTab label="resources.club/posts.tabs.details">
      <TextInput source="id" validate={[required()]} disabled />
      <ReferenceInput
        source="owner"
        reference="users"
        validate={[required()]}
        disabled
      >
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <TextInput source="slug" validate={[required()]} />
      <TextInput source="title" validate={[required()]} />
      <BooleanInput source="is_pin" />
      <BooleanInput source="is_verify" />
    </FormTab>
    <FormTab label="resources.club/posts.tabs.content" path="content">
      <MarkdownInput
        source="description"
        fullWidth
        validate={[required()]}
        addLabel={false}
      />
    </FormTab>
    <FormTab label="resources.club/posts.tabs.media" path="media">
      <UploadImageInput
        source="logo"
        section={sectionChoices.POST}
        attribute={attributeChoices.LOGO}
        private={false}
      />
      <UploadImageInput
        source="header"
        section={sectionChoices.POST}
        attribute={attributeChoices.HEADER}
        private={false}
      />
    </FormTab>
  </TabbedForm>
);

export default Form;
