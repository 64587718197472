import React from 'react';
import { ImageField, ImageInput, useInput, useNotify } from 'react-admin';
import Progress from '@material-ui/core/LinearProgress';

import { supportedImages } from '@src/configuration';
import uploadClient from '@src/data/uploadClient';
import { Attribute, Section } from '@src/types';

type Props = {
  private: boolean;
  section: Section;
  attribute: Attribute;
  source: string;
  label?: string;
  validate?: any;
};

const UploadImageInput: React.FC<Props> = (props) => {
  const {
    input: { name, onChange },
    meta: { touched, error },
    isRequired,
  } = useInput(props);
  const notify = useNotify();
  const [percent, setPercent] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const handleUploadProgress = React.useCallback((progressEvent) => {
    const newPercent = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    if (newPercent >= 100) {
      setPercent(100);
    } else {
      setPercent(newPercent);
    }
  }, []);
  const handleChange = React.useCallback(
    async (file: File | null) => {
      if (file instanceof File) {
        try {
          setUploading(true);
          const data = new FormData();
          const url = {
            source: '',
          };
          data.set('chunk', file);
          data.set('private', props.private ? 'true' : 'false');
          data.set('section', props.section.toString());
          data.set('attribute', props.attribute.toString());
          const response = await uploadClient.post(
            '/cms/learn/course/upload',
            data,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: handleUploadProgress,
            }
          );
          url.source = response.data.url;
          onChange(url);
          setUploading(false);
          setPercent(-1);
        } catch (e) {
          onChange(null);
          setUploading(false);
          setPercent(-1);
          notify(`Image upload failed: ${e.message}`, 'error');
        }
      } else {
        onChange(null);
      }
    },
    [
      props.private,
      props.section,
      props.attribute,
      handleUploadProgress,
      onChange,
      notify,
    ]
  );

  const handleFormat = React.useCallback((value) => ({ src: value }), []);
  const handleParse = React.useCallback((value) => value?.src, []);

  return (
    <div>
      <ImageInput
        {...props}
        onChange={handleChange}
        error={!!(touched && error)}
        helperText={touched && error}
        required={isRequired}
        accept={supportedImages}
        multiple={false}
        format={handleFormat}
        parse={handleParse}
        label={props.label}
      >
        <ImageField source="src.source" title={name} />
      </ImageInput>
      {uploading && (
        <Progress
          variant={percent !== -1 ? 'determinate' : undefined}
          value={percent}
        />
      )}
    </div>
  );
};

export default UploadImageInput;
