import { AxiosError } from 'axios';
import gravatar from 'gravatar';
import { AuthProvider } from 'ra-core';

import client from '@src/data/client';

import * as jwtManager from './jwtManager';
import { Login } from './types';

const authProvider: AuthProvider = {
  login: async ({ email, password }: Login) => {
    await jwtManager.login(email, password);
    return Promise.resolve();
  },
  logout: () => {
    jwtManager.erase();
    return Promise.resolve();
  },
  checkError: async (e: AxiosError) => {
    switch (e?.response?.status) {
      case 403:
        throw new Error('ra.auth.no_permission');
      case 401:
        return await jwtManager.refreshAccessToken();
      default:
        return Promise.resolve();
    }
  },
  getIdentity: async () => {
    try {
      const {
        data: { id, username, email, avatar },
      } = await client.get('/user/generics/profile');
      return Promise.resolve({
        id,
        fullName: username,
        avatar: avatar ?? gravatar.url(email, { d: 'mp' }),
      });
    } catch (e) {
      throw new Error('ra.auth.auth_check_error');
    }
  },
  checkAuth: async () => {
    if (!jwtManager.getAccessToken()) {
      await jwtManager.refreshAccessToken();
    }
    return jwtManager.getAccessToken() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: async () => {
    await jwtManager.waitForRefreshAccessToken();
    const permissions = jwtManager.getPermissions();
    if (permissions) {
      return Promise.resolve(permissions);
    }
    return Promise.reject();
  },
};

export default authProvider;
