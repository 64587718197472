import React from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

import UploadImageInput from '@src/components/UploadImageInput';
import { attributeChoices, sectionChoices } from '@src/configuration';

const Form: React.FC<any> = (props) => (
  <SimpleForm {...props} redirect="show">
    <TextInput source="id" disabled />
    <TextInput source="slug" validate={[required()]} />
    <TextInput source="name" validate={[required()]} />
    <TextInput source="name_fa" validate={[required()]} />
    <ReferenceInput source="category" reference="skill/category">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="document_link" />
    <TextInput source="back_link" />
    <TextInput
      source="description"
      multiline
      fullWidth
      validate={[required()]}
    />
    <UploadImageInput
      section={sectionChoices.SKILL}
      attribute={attributeChoices.LOGO}
      private={false}
      source="logo"
    />
    <UploadImageInput
      section={sectionChoices.SKILL}
      attribute={attributeChoices.HEADER}
      private={false}
      source="header"
    />
  </SimpleForm>
);

export default Form;
