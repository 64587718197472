import React from 'react';
import { Loading, useInput, useTranslate } from 'react-admin';
import ReactMde from 'react-mde';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Markdown from '@src/components/Markdown';
import { attributeChoices, sectionChoices } from '@src/configuration';
import client from '@src/data/client';

import 'react-mde/lib/styles/css/react-mde-all.css';

const useStyles = makeStyles((theme) => ({
  input: {
    '& textarea': {
      fontFamily: theme.typography.fontFamily,
      outline: 'none',
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    '& button': { outline: 'none' },
  },
}));

const paste = {
  saveImage: async function* (_, file) {
    try {
      const data = new FormData();
      data.set('chunk', file);
      data.set('private', 'false');
      data.set('section', sectionChoices.OTHER.toString());
      data.set('attribute', attributeChoices.IMAGE.toString());
      const response = await client.post('/cms/learn/course/upload', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      yield response.data.url;
      return true;
    } catch (e) {
      return false;
    }
  },
};

const childProps = {
  textArea: { dir: 'auto' },
};

const MarkdownInput: React.FC<any> = (props) => {
  const [tab, setTab] = React.useState<'write' | 'preview'>('write');
  const {
    input: { name, onChange, value },
    isRequired,
  } = useInput(props);
  const classes = useStyles();
  const translate = useTranslate();
  const l18n = React.useMemo(
    () => ({
      write: translate('ra.input.markdown.write'),
      preview: translate('ra.input.markdown.preview'),
      uploadingImage: translate('ra.input.markdown.uploadingImage'),
      pasteDropSelect: translate('ra.input.markdown.pasteDropSelect'),
    }),
    [translate]
  );

  return (
    <FormControl
      id={name}
      required={isRequired}
      fullWidth={true}
      className={classes.input}
    >
      <ReactMde
        onChange={onChange}
        value={value}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<Markdown>{markdown}</Markdown>)
        }
        l18n={l18n}
        loadingPreview={<Loading />}
        onTabChange={setTab}
        selectedTab={tab}
        readOnly={props.disabled}
        childProps={childProps}
        paste={paste}
        minEditorHeight={512}
        maxEditorHeight={512}
        minPreviewHeight={512}
      />
    </FormControl>
  );
};

MarkdownInput.defaultProps = {
  addLabel: true,
  fullWidth: true,
};

export default MarkdownInput;
