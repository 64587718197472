import React from 'react';
import { regex, required, TextInput } from 'react-admin';

const DurationInput: React.FC<React.ComponentProps<typeof TextInput>> = (
  props
) => {
  return (
    <TextInput
      {...props}
      helperText="مانند 00:05:00"
      validate={[required(), regex(/\d{2}:\d{2}:\d{2}/)]}
    />
  );
};

DurationInput.defaultProps = {
  addLabel: true,
};

export default DurationInput;
