import React from 'react';
import { Button, useNotify, useRefresh, useTranslate } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Publish from '@material-ui/icons/Publish';

import client from '@src/data/client';

import { status, statusMap } from './choices';

type Props = {
  record?: any;
  resource?: any;
};

const PublishPost: React.FC<Props> = ({ record }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const generateClickHandler = React.useCallback(
    (new_status: number) => async () => {
      try {
        if (!record || record.current_status === new_status) {
          return;
        }
        await client.post(`/cms/club/post/manage/status/${record.id}`, {
          new_status,
        });
        notify('resources.club/posts.actions.status_set_succeeded', 'success', {
          status: translate(statusMap?.[new_status]?.name),
        });
      } catch (e) {
        notify('resources.club/posts.actions.status_set_failed', 'error');
      }
      handleClose();
      refresh();
    },
    [handleClose, notify, record, refresh, translate]
  );

  if (!record) return null;

  return (
    <>
      <Button
        onClick={handleClick}
        label={`${translate(
          'resources.club/posts.fields.current_status'
        )}: ${translate(statusMap?.[record.current_status]?.name)}`}
      >
        <Publish />
      </Button>
      <Menu
        id="publish-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {status.map((s) => (
          <MenuItem
            disabled={record.current_status === s.id}
            key={s.id.toString()}
            onClick={generateClickHandler(s.id)}
          >
            {translate(s.name)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(PublishPost);
