import React from 'react';
import {
  BooleanField,
  ReferenceField,
  SelectField,
  Show as RaShow,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import MarkdownField from '@src/components/MarkdownField';
import Title from '@src/components/Title';

import { status } from './choices';
import ShowActions from './ShowActions';

const Show: React.FC = (props) => (
  <RaShow
    {...props}
    title={<Title {...props} type="show" source="title" />}
    actions={<ShowActions />}
  >
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="slug" />
      <TextField source="title" />
      <ReferenceField source="owner" reference="users">
        <TextField source="email" />
      </ReferenceField>
      <LocaleDateField source="created_at" />
      <LocaleDateField source="updated_at" />
      <SelectField source="current_status" choices={status} />
      <BooleanField source="is_pin" />
      <BooleanField source="is_verify" />
      <MarkdownField source="description" fullWidth />
    </SimpleShowLayout>
  </RaShow>
);

export default Show;
