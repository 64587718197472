import React from 'react';
import {
  BooleanField,
  EmailField,
  ImageField,
  NumberField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin';
import { SelectField } from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Title from '@src/components/Title';

import { gender } from './choices';

const Show: React.FC = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" source="username" />}>
    <TabbedShowLayout>
      <Tab label="resources.users.tabs.details">
        <TextField source="id" />
        <ImageField source="header" />
        <ImageField source="avatar" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="username" />
        <EmailField source="email" />
        <TextField source="mobile_number" />
        <SelectField source="gender" choices={gender} />
        <UrlField source="github" />
        <UrlField source="linkedin" />
        <UrlField source="website_url" />
        <TextField source="city" />
        <BooleanField source="is_developer" />
        <BooleanField source="is_verified" />
        <TextField source="bio" />
        <LocaleDateField source="birth_date" />
        <LocaleDateField source="date_joined" />
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
      </Tab>
      <Tab label="resources.users.tabs.wallet" path="wallet">
        <NumberField
          source="wallet.user_charge"
          options={{ style: 'currency', currency: 'IRR' }}
        />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
