import React from 'react';
import { FileField, useTranslate } from 'react-admin';

const ProFileField: React.FC<React.ComponentProps<typeof FileField>> = (
  props
) => {
  const translate = useTranslate();
  return (
    <FileField
      {...props}
      title={translate('ra.download')}
      download
      target="_blank"
    />
  );
};

ProFileField.defaultProps = {
  addLabel: true,
};

export default ProFileField;
