import React from 'react';
import { Create as RaCreate } from 'react-admin';

import Form from './Form';

const Create: React.FC = (props) => (
  <RaCreate {...props}>
    <Form />
  </RaCreate>
);

export default Create;
