import React from 'react';
import { Typography } from '@material-ui/core';

const H4Block: React.FC<any> = (props) => (
  <Typography
    gutterBottom
    variant="caption"
    component="h4"
    paragraph
    {...props}
  />
);

export default React.memo(H4Block);
