import merge from 'lodash/merge';

import baseTheme from './base';

const Theme = (language = 'fa', rtl = true) => {
  return merge(baseTheme(language, rtl), {
    palette: {
      type: 'light',
      primary: {
        main: '#5DD39E',
        contrastText: '#222130',
      },
      secondary: {
        main: '#525174',
        contrastText: '#222130',
      },
    },
  });
};

export default Theme;
