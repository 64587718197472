import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  email,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from 'react-admin';

const Form: React.FC<any> = (props) => (
  <SimpleForm {...props} redirect="show">
    <TextInput source="id" disabled />
    <ReferenceInput source="user" reference="users" validate={[required()]}>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <TextInput
      type="email"
      source="email_contact"
      validate={[email(), required()]}
    />
    <TextInput type="phone" source="phone_contact" validate={[required()]} />
    <TextInput source="instagram" />
    <BooleanInput source="active" defaultValue={true} />
    <TextInput source="description" fullWidth multiline />
  </SimpleForm>
);

export default Form;
