import { GET_LIST, GET_MANY } from 'react-admin';

const generateBaseURL = (resource: string, type: string) => {
  switch (resource) {
    case 'learn/courses':
      return '/cms/learn/course';
    case 'learn/course/chapter':
      return '/cms/learn/course/chapter';

    case 'skill':
      return '/cms/common/skill';

    case 'skill/category':
      return '/cms/common/skill/category';

    case 'teacher':
      return '/cms/user/teacher';

    case 'users':
      switch (type) {
        case GET_LIST:
        case GET_MANY:
          return '/cms/user/';
        default:
          return '/cms/user';
      }
    case 'forum/questions':
      return '/cms/forum/question';

    case 'forum/answers':
      return '/cms/forum/answer';

    case 'forum/question/replies':
      return '/cms/forum/reply/question';

    case 'forum/answer/replies':
      return '/cms/forum/reply/answer';

    case 'club/posts':
      return '/cms/club/post';

    case 'tags':
      return '/cms/common/tag';

    case 'authentication':
      return `/${resource}`;

    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
};

export default generateBaseURL;
