import React from 'react';
import { required, SimpleForm, TextInput } from 'react-admin';

import MarkdownInput from '@src/components/MarkdownInput';

const Form: React.FC<any> = (props) => (
  <SimpleForm {...props} redirect="show">
    <TextInput source="id" disabled />
    <TextInput source="slug" validate={[required()]} />
    <TextInput source="name" validate={[required()]} />
    <TextInput source="name_fa" validate={[required()]} />
    <MarkdownInput source="description" fullWidth validate={[required()]} />
  </SimpleForm>
);

export default Form;
