import React from 'react';

type Props = {
  alt?: string;
  src?: string;
  title?: string;
};

const ImageBlock: React.FC<Props> = ({ alt, src, title }) => {
  return <img alt={alt} src={src} title={title} style={{ maxWidth: 475 }} />;
};

export default React.memo(ImageBlock);
