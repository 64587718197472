import React from 'react';
import { Typography } from '@material-ui/core';

import H1Block from './H1Block';
import H2Block from './H2Block';
import H3Block from './H3Block';
import H4Block from './H4Block';

type Props = {
  level: number;
  children: any;
};

const HeadingBlock: React.FC<Props> = ({ level, ...props }) => {
  switch (level) {
    case 1:
      return <H1Block {...props} />;
    case 2:
      return <H2Block {...props} />;
    case 3:
      return <H3Block {...props} />;
    case 4:
      return <H4Block {...props} />;
    default:
      return <Typography paragraph {...props} />;
  }
};

export default React.memo(HeadingBlock);
