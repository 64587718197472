import React from 'react';
import { Show as RaShow, Tab, TabbedShowLayout, TextField } from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import MarkdownField from '@src/components/MarkdownField';
import Title from '@src/components/Title';

const Show: React.FC = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" source="name" />}>
    <TabbedShowLayout>
      <Tab label="resources.tags.tabs.details">
        <TextField source="id" />
        <TextField source="slug" />
        <TextField source="name" />
        <TextField source="name_fa" />
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
      </Tab>
      <Tab label="resources.tags.tabs.description" path="description">
        <MarkdownField source="description" addLabel={false} />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
