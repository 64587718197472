import React from 'react';
import { get } from 'lodash';

import Markdown from '@src/components/Markdown';

type Props = {
  record?: object;
  source: string;
  label?: any;
  addLabel?: boolean;
  fullWidth?: boolean;
};

const MarkdownField: React.FC<Props> = ({ source, record = {} }) => (
  <Markdown>{get(record, source)}</Markdown>
);

MarkdownField.defaultProps = {
  addLabel: true,
};

export default MarkdownField;
