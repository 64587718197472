import React from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';

import MenuLinkContext from './MenuLinkContext';

type Props = {
  Resource: any;
};

const ResourceMenuLink: React.FC<Props> = ({ Resource }) => {
  const translate = useTranslate();
  const { onMenuClick, dense, open } = React.useContext(MenuLinkContext);

  return (
    <MenuItemLink
      to={`/${Resource.name}`}
      primaryText={translate(`resources.${Resource.name}.name`, {
        smart_count: 2,
      })}
      leftIcon={<Resource.icon />}
      onClick={onMenuClick}
      sidebarIsOpen={open}
      dense={dense}
    />
  );
};

export default ResourceMenuLink;
