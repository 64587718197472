import React from 'react';
import { Edit as RaEdit } from 'react-admin';

import Form from './Form';

const Edit: React.FC = (props) => (
  <RaEdit {...props}>
    <Form />
  </RaEdit>
);

export default Edit;
