import React from 'react';
import {
  BooleanField,
  ReferenceField,
  Show as RaShow,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';

const Show: React.FC = (props) => (
  <RaShow {...props}>
    <TabbedShowLayout>
      <Tab label="resources.teacher.tabs.details">
        <TextField source="id" />
        <ReferenceField source="user" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField source="email_contact" />
        <TextField source="phone_contact" />
        <TextField source="instagram" />
        <BooleanField source="active" />
        <TextField source="description" />
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
