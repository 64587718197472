import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormTab,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';

import DurationInput from '@src/components/DurationInput';
import MarkdownInput from '@src/components/MarkdownInput';
import UploadFileInput from '@src/components/UploadFileInput';
import UploadImageInput from '@src/components/UploadImageInput';
import {
  attributeChoices,
  sectionChoices,
  supportedVideos,
} from '@src/configuration';

const Form: React.FC<any> = (props) => (
  <TabbedForm {...props} redirect="show">
    <FormTab label="resources.learn/courses.tabs.details">
      <TextInput source="id" disabled />
      <TextInput source="slug" validate={[required()]} />
      <TextInput source="title" validate={[required()]} />
      <TextInput source="version" />
      <ReferenceArrayInput
        source="teachers"
        reference="teacher"
        validate={[required()]}
      >
        <AutocompleteArrayInput optionText="email_contact" />
      </ReferenceArrayInput>
      <UploadImageInput
        section={sectionChoices.COURSE}
        attribute={attributeChoices.LOGO}
        private={false}
        source="logo"
        validate={[required()]}
      />
      <UploadImageInput
        section={sectionChoices.COURSE}
        attribute={attributeChoices.HEADER}
        private={false}
        source="header"
        validate={[required()]}
      />
      <UploadFileInput
        source="introduction"
        section={sectionChoices.COURSE}
        attribute={attributeChoices.INTRO}
        accept={supportedVideos}
        private={false}
        // validate={[required()]}
      />
      <ReferenceInput source="category" reference="skill">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput
        source="tags"
        reference="tags"
        validate={[required()]}
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <NumberInput source="priority" validate={[required()]} />
      <DurationInput source="time" />
      <BooleanInput source="is_pin" />
      <BooleanInput source="active" />
      <TextInput source="back_link" />
      <TextInput source="document_link" />
    </FormTab>
    <FormTab
      label="resources.learn/courses.tabs.description"
      path="description"
    >
      <MarkdownInput
        source="description"
        fullWidth
        validate={[required()]}
        addLabel={false}
      />
    </FormTab>
    <FormTab label="resources.learn/courses.tabs.price" path="price">
      <NumberInput
        step={1}
        source="price.final_price"
        validate={[required()]}
        helperText="﷼"
      />
      <NumberInput
        step={1}
        source="price.show"
        validate={[required()]}
        helperText="﷼"
      />
      <NumberInput
        step={1}
        source="price.tax"
        validate={[required()]}
        helperText="﷼"
        defaultValue={0}
      />
    </FormTab>
  </TabbedForm>
);

export default Form;
