import React from 'react';
import { Admin, Resource } from 'react-admin';
import CssBaseline from '@material-ui/core/CssBaseline';

import authProvider from '@src/authentication';
import Dashboard from '@src/components/Dashboard';
import Layout from '@src/components/Layout';
import Login from '@src/components/Login';
import RTL from '@src/components/RTL';
import dataProvider from '@src/data';
import history from '@src/history';
import i18nProvider from '@src/i18n';
import * as resources from '@src/resources';
import customRoutes from '@src/routes';
import customReducers from '@src/store';

const App = () => (
  <>
    <CssBaseline />
    <RTL>
      <Admin
        history={history}
        title={process.env.REACT_APP_NAME}
        layout={Layout}
        dashboard={Dashboard}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={Login}
        customReducers={customReducers}
        customRoutes={customRoutes}
      >
        <Resource {...resources.Course} />
        <Resource {...resources.CourseDetail} />
        <Resource {...resources.Skill} />
        <Resource {...resources.Teacher} />
        <Resource {...resources.User} />
        <Resource {...resources.Question} />
        <Resource {...resources.Answer} />
        <Resource {...resources.Post} />
        <Resource {...resources.Tag} />
        <Resource name="skill/category" />
        <Resource name="forum/question/replies" />
        <Resource name="forum/answer/replies" />
      </Admin>
    </RTL>
  </>
);

export default App;
