import React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  FormTab,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import DurationInput from '@src/components/DurationInput';
import MarkdownInput from '@src/components/MarkdownInput';
import UploadFileInput from '@src/components/UploadFileInput';
import UploadVideoInput from '@src/components/UploadVideoInput';
import {
  attributeChoices,
  sectionChoices,
  supportedFiles,
  supportedVideos,
} from '@src/configuration';

const Form: React.FC<any> = (props) => {
  const location = useLocation<{ course?: string }>();
  return (
    <TabbedForm initialValues={location.state} {...props} redirect="show">
      <FormTab label="resources.learn/course/chapter.tabs.details">
        {location.state?.course ? (
          <ReferenceInput source="course" reference="learn/courses" disabled>
            <AutocompleteInput optionText="title" />
          </ReferenceInput>
        ) : (
          <ReferenceInput
            source="course"
            reference="learn/courses"
            validate={[required()]}
            disabled
          >
            <AutocompleteInput optionText="title" />
          </ReferenceInput>
        )}
        <ReferenceInput
          source="teacher"
          reference="teacher"
          validate={[required()]}
        >
          <AutocompleteInput optionText="email_contact" />
        </ReferenceInput>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="chapter_number" validate={[required()]} />
        <TextInput source="slug" validate={[required()]} />
        <DurationInput source="time" />
        <BooleanInput source="active" />
        <BooleanInput source="is_demo" />
        <ReferenceArrayInput
          source="tags"
          reference="tags"
          validate={[required()]}
        >
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </FormTab>
      <FormTab
        label="resources.learn/course/chapter.tabs.description"
        path="description"
      >
        <MarkdownInput
          source="description"
          validate={[required()]}
          addLabel={false}
        />
      </FormTab>
      <FormTab label="resources.learn/course/chapter.tabs.media" path="media">
        <UploadVideoInput
          source="video.source"
          presigned="source"
          section={sectionChoices.CHAPTER}
          private={true}
          label="resources.learn/course/chapter.fields.video_source"
        />
        <UploadVideoInput
          source="video.q_720"
          presigned="q_720"
          section={sectionChoices.CHAPTER}
          private={true}
          label="resources.learn/course/chapter.fields.video_720"
        />

        <UploadVideoInput
          source="video.q_480"
          presigned="q_480"
          section={sectionChoices.CHAPTER}
          private={true}
          label="resources.learn/course/chapter.fields.video_480"
        />
        <UploadFileInput
          source="demo"
          accept={supportedVideos}
          section={sectionChoices.CHAPTER}
          attribute={attributeChoices.DEMO}
          private={false}
        />
        <UploadFileInput
          source="files"
          accept={supportedFiles}
          section={sectionChoices.CHAPTER}
          attribute={attributeChoices.FILES}
          private={true}
        />
      </FormTab>
    </TabbedForm>
  );
};

export default Form;
