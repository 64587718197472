import React from 'react';
import { Title, useAuthenticated, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';

const Dashboard: React.FC = () => {
  useAuthenticated();
  const translate = useTranslate();
  return (
    <Card>
      <Title title="ra.page.dashboard" />
      <CardContent>
        <Typography component="h2" variant="h6">
          {translate('ra.dashboard.welcome.title')}
        </Typography>
        <Typography component="p" variant="body2">
          {translate('ra.dashboard.welcome.description')}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end' }}>
        <Button component={Link} to="/">
          <LinkIcon style={{ paddingRight: '0.5em' }} />
          {translate('ra.dashboard.welcome.web_button')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default React.memo(Dashboard);
