import React from 'react';
import { useLocale } from 'react-admin';

import { useRTL } from '@src/i18n';
import { dark, light } from '@src/themes';

import useThemeName from './useThemeName';

const useTheme = () => {
  const rtl = useRTL();
  const locale = useLocale();

  const themeName = useThemeName();
  return React.useMemo(
    () => (themeName === 'dark' ? dark(locale, rtl) : light(locale, rtl)),
    [locale, rtl, themeName]
  );
};

export default useTheme;
