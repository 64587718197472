import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  Show as RaShow,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from 'react-admin';
import { SelectField } from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import FullNameField from '@src/components/FullNameField';
import LocaleDateField from '@src/components/LocaleDateField';
import MarkdownField from '@src/components/MarkdownField';
import Title from '@src/components/Title';

import { status } from './choices';

const Show: React.FC<any> = (props) => (
  <RaShow {...props} title={<Title {...props} type="show" source="title" />}>
    <TabbedShowLayout>
      <Tab label="resources.forum/questions.tabs.details">
        <TextField source="id" />
        <TextField source="slug" />
        <LocaleDateField source="created_at" />
        <BooleanField source="archive" />
        <SelectField source="current_status" choices={status} />
        <BooleanField source="is_updated" />
        <TextField source="title" />
        <ReferenceField source="owner.id" reference="users">
          <FullNameField />
        </ReferenceField>
        <MarkdownField source="description" />

        <JsonField
          source="monitor"
          reactJsonOptions={{
            theme: 'google',
            name: null,
          }}
        />

        <ReferenceArrayField reference="tags" source="tags">
          <SingleFieldList linkType="show">
            <ChipField source="id" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Tab>
      <Tab label="resources.forum/questions.tabs.answers" path="answers">
        <ReferenceManyField
          target="question_id"
          reference="forum/answers"
          addLabel={false}
        >
          <Datagrid rowClick="show">
            <MarkdownField source="body" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="resources.forum/questions.tabs.comments" path="comments">
        <ReferenceManyField
          target="question_id"
          reference="forum/question/replies"
          addLabel={false}
        >
          <Datagrid>
            <MarkdownField source="body" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </RaShow>
);

export default Show;
