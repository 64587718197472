import { authentication } from '@src/data/interceptors';
import axios from 'axios';

import paramsSerializer from './paramsSerializer';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_UPLOAD_URL}/v1.0`,
  paramsSerializer,
});

client.interceptors.request.use(authentication);

export default client;
