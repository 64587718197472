import * as jwtManager from '@src/authentication/jwtManager';
import { defaultLanguage, languageKey } from '@src/configuration';
import { AxiosRequestConfig } from 'axios';

export const authentication = async (config: AxiosRequestConfig) => {
  config.headers['Accept-Language'] =
    localStorage.getItem(languageKey) || defaultLanguage;

  const token = jwtManager.getAccessToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  } else {
    await jwtManager.refreshAccessToken();
    config.headers['Authorization'] = `Bearer ${jwtManager.getAccessToken()}`;
  }
  return config;
};
