import React from 'react';
import {
  Datagrid,
  List as RaList,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import Title from '@src/components/Title';
import { perPage } from '@src/configuration';

import { status } from './choices';

const List: React.FC<any> = (props) => (
  <>
    <RaList
      {...props}
      title={<Title {...props} type="list" />}
      pagination={<Pagination />}
      perPage={perPage}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="title" />
        <ReferenceField source="owner" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <LocaleDateField source="created_at" />
        <LocaleDateField source="updated_at" />
        <SelectField source="current_status" choices={status} />
      </Datagrid>
    </RaList>
  </>
);

export default List;
