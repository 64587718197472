import React from 'react';
import { Button } from 'react-admin';
import { Link } from 'react-router-dom';

import CourseDetail from '@src/resources/CourseDetail';

type Props = {
  record?: any;
  resource?: any;
};

const AddNewChapter: React.FC<Props> = ({ record, resource }) => {
  if (!record) return null;

  return (
    <Button
      component={Link}
      to={{
        pathname: `/${CourseDetail.name}/create`,
        state: { course: record.id },
      }}
      label={`resources.${resource}.actions.new_chapter`}
    >
      <CourseDetail.icon />
    </Button>
  );
};

export default React.memo(AddNewChapter);
