export const __DEV__ = process.env.NODE_ENV === 'development';

export const perPage = 50;

export const defaultLanguage = 'fa';
export const defaultTheme = 'dark';
export const defaultExpires = 120000;

export const languageKey = 'locale';
export const themeKey = 'theme';

export const supportedFiles = 'application/pdf,text/html';
export const supportedAudios = 'audio/aac,audio/mpeg';
export const supportedVideos = 'video/x-matroska,video/mp4';
export const supportedImages = 'image/jpeg,image/png';

export const attributeChoices = {
  LOGO: 1,
  HEADER: 2,
  INTRO: 3,
  DEMO: 4,
  POSTER: 5,
  AUDIO: 6,
  VIDEO: 7,
  IMAGE: 8,
  FILES: 9,
};

export const sectionChoices = {
  OTHER: 0,
  COURSE: 1,
  CHAPTER: 2,
  SKILL: 3,
  POST: 4,
  FORUM: 5,
};
