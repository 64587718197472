import React from 'react';
import {
  BooleanField,
  Datagrid,
  EditButton,
  List as RaList,
  TextField,
} from 'react-admin';

import LocaleDateField from '@src/components/LocaleDateField';
import Pagination from '@src/components/Pagination';
import ResourceHelmet from '@src/components/ResourceHelmet';
import { perPage } from '@src/configuration';

const List: React.FC = (props) => (
  <>
    <ResourceHelmet {...props} />
    <RaList
      {...props}
      pagination={<Pagination />}
      perPage={perPage}
      sort={{ field: 'created_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="email_contact" />
        <BooleanField source="active" />
        <LocaleDateField source="created_at" />
        <EditButton />
      </Datagrid>
    </RaList>
  </>
);

export default List;
