import axios from 'axios';

import paramsSerializer from './paramsSerializer';

const simpleClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1.0`,
  paramsSerializer,
});

export default simpleClient;
